import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StateService } from "src/app/services/state.service";
// import { MqttService } from "src/app/services/mqtt.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { FullCalendarComponent } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { RestService } from "src/app/services/rest.service";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
// import { time } from "console";
import * as XLSX from "xlsx";
import { DeviceData } from "src/app/models/get-device.interface";

@Component({
	selector: "app-device",
	templateUrl: "./device.component.html",
	styleUrls: ["./device.component.css"],
})
export class DeviceComponent implements OnInit, OnDestroy {
	@ViewChild("calendar", { static: false })
	// calendarComponent: FullCalendarComponent;
	device = {};
	showConfig;

	guage_color = ["#007bff", "#f7ac4d", "#ff0000"];

	lat: number = 19;
	lng: number = 73;
	zoom: number = 15;
	addDevice: UntypedFormGroup;

	device_id;
	id: number;
	uuid;
	isExpired = false;
	expire_msg = "";
	// iconUrl =
	//   "https://www.shareicon.net/data/32x32/2016/01/28/710289_light-bulb_512x512.png";
	iconUrl = window.location.href.toString().split("#")[0] + "assets/motor.png";
	gaugeType = "arch";

	calendarPlugins = [
		dayGridPlugin,
		timeGridPlugin,
		listPlugin,
		interactionPlugin,
	]; // important!

	selected_year = new Date().getFullYear();
	selected_month = new Date().getMonth() + 1;
	selected_date: Date;
	s1_start_time;
	s1_end_time;
	s2_start_time;
	s2_end_time;

	config_name = {
		rssid: "ROUTER SSID",
		id: "PRODUCT ID",
		lpt: "LOOPING TIME",
		prot: "PROTECTION",
		prt: "PORT",
		pw: "PASSWORD",
		rpw: "ROUTER PASSWORD",
		time: "TIME",
		timer: "TIMER STATUS",
		un: "USERNAME",
		url: "WEBSITE",
		amode: "AUTO MODE",
	};
	analog_name = [
		"Voltage(R)",
		"Voltage(Y)",
		"Voltage(B)",
		"Current(R)",
		"Current(Y)",
		"Current(B)",
		"Imbalance voltage(R-Y)",
		"Imbalance voltage(Y-B)",
		"Imbalance voltage(B-R)",
		"Imbalance current(R-Y)",
		"Imbalance current(Y-B)",
		"Imbalance current(B-R)",
		"Technical fault(R)",
		"Technical fault(Y)",
		"Technical fault(B)",
		"",
		"",
		"",
		"",
		"Phase reverse",
	];
	config_key;
	config_value;
	analog_key;
	analog_status;
	analog_min;
	analog_max;
	multiplier;

	from_date = new Date().toISOString().split("T")[0];
	to_date = new Date().toISOString().split("T")[0];
	report_date = new Date().toISOString().split("T")[0];
	report_month =
		new Date().toISOString().split("T")[0].split("-")[0] +
		"-" +
		new Date().toISOString().split("T")[0].split("-")[1];
	run_log_data = [];
	report_data = [];
	month_report_data = [];

	columns = [
		{ name: "time", prop: "date" },
		{ name: "Voltage 1", prop: "v1" },
		{ name: "Voltage 2", prop: "v2" },
		{ name: "Voltage 3", prop: "v3" },
		{ name: "Current 1", prop: "c1" },
		{ name: "Current 2", prop: "c2" },
		{ name: "Current 3", prop: "c3" },
	];

	selectedDevice = {} as DeviceData;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		public _state: StateService,
		public _rest: RestService,
		private _spinner: NgxSpinnerService,
		private _tostr: ToastrService,
	) {
		this._route.params.subscribe((params) => {
			this.id = params["id"];
			const filteredDevice = this._state.devices_list.filter(device => device.id === this.id);
			console.log("====>", this.id, this._state.devices_list, this._state.devices)
			this.selectedDevice = filteredDevice[0];
			// console.log("====>", this.id, this._state.devices_list, filteredDevice)
			if (this.selectedDevice) {
				this.device_id = this.selectedDevice["id"];
				this.uuid = this.selectedDevice["uuid"];
				// console.log();
				if (
					new Date(this.selectedDevice["exp_date"]).setMonth(
						new Date(this.selectedDevice["exp_date"]).getMonth() - 1,
					) < new Date().getTime()
				) {
					this.expire_msg =
						"service of this device will expire on : " +
						new Date(this.selectedDevice["exp_date"]);
				}
				if (new Date(this.selectedDevice["exp_date"]) < new Date()) {
					this.isExpired = true;
				}
				this.backToDefault();
				this.report_data = [];
				this.month_report_data = [];
				// this._mqtt.get_data(this.id);
			} else {
				this._router.navigate(["/app"]);
			}
		});
	}

	ngOnInit() {
		this.showConfig = 30;
		this.device = this.selectedDevice;
		this.addDevice = new UntypedFormGroup({
			id: new UntypedFormControl("", [Validators.required]),
			name: new UntypedFormControl("", [Validators.required]),
			area: new UntypedFormControl("", [Validators.required]),
			type: new UntypedFormControl("", [Validators.required]),
			flow_multiplier: new UntypedFormControl("0", [Validators.required]),
		});
	}

	ngOnDestroy(): void {
		// console.log("destroied");
	}

	backToDefault() {
		this._state.schedule[this.id] = [];
		this.selected_year = new Date().getFullYear();
		this.selected_date = undefined;
		this.s1_start_time = undefined;
		this.s1_end_time = undefined;
		this.s2_start_time = undefined;
		this.s2_end_time = undefined;
	}

	switch_light(switch_id, value) {
		this.showConfig--;
		// let msg = "";
		// if (value) msg = "Do you want turn it on ?";
		// else msg = "Do you want turn it off ?";
		// if (confirm(msg)) {
		//   this._mqtt.toggle_switch(this.id, switch_id, value);
		// }
	}

	fetch_schedule() {
		this._state.schedule[this.id] = [];
		this._spinner.show();
		// this._mqtt.get_schedule(this.id);
		setTimeout(() => {
			this._spinner.hide();
			if (this._state.schedule[this.id] === []) {
				this._tostr.error("Unable to fetch data in time", "Sorry");
			}
		}, 10000);
	}

	update_time(i) {
		let key = ("0" + i).slice(-2);
		let status = this._state.schedule[this.id][i][0] ? 1 : 0;
		let from = (
			"0000" +
			(Number(this._state.schedule[this.id][i][1].split(":")[0] * 60) +
				Number(this._state.schedule[this.id][i][1].split(":")[1]))
		).slice(-4);
		let to = (
			"0000" +
			(Number(this._state.schedule[this.id][i][2].split(":")[0] * 60) +
				Number(this._state.schedule[this.id][i][2].split(":")[1]))
		).slice(-4);
		let value = `${key},0,0000,0000`;
		if (from !== to) {
			value = `${key},${status},${from},${to}`;
		}
		console.log(value);
		if (confirm("Are You Sure ?")) {
			// this._mqtt.update_config(this.id, "stime", value);
			this.fetch_schedule();
		}
	}

	// if (this._state.schedule[this.id] === []) {
	//   for (
	//     let i = 0;
	//     i <
	//     new Date(this.selected_year, this.selected_month, 0).getDate() * 2;
	//     i++
	//   ) {
	//     this._state.schedule[this.id][i] = {
	//       title: `00:00`,
	//       start: `${this.selected_year}-${this.selected_month}-${(
	//         "0" + this.selected_date.getDate()
	//       ).slice(-2)}T$00:00:00`,
	//       end: `${this.selected_year}-${this.selected_month}-${(
	//         "0" + this.selected_date.getDate()
	//       ).slice(-2)}T00:00:00`
	//     };
	//   }
	// }

	handleDateClick(e) {
		if (this._state.schedule[this.id].length !== 0) {
			this.selected_date = e.date ? e.date : e.event.start;
			let s1_start_time =
				this._state.schedule[this.id][this.selected_date.getDate() * 2 - 2][
					"start"
				].split("T")[1];
			this.s1_start_time = `${s1_start_time.split(":")[0]}:${
				s1_start_time.split(":")[1]
			}`;

			let s1_end_time =
				this._state.schedule[this.id][this.selected_date.getDate() * 2 - 2][
					"end"
				].split("T")[1];
			this.s1_end_time = `${s1_end_time.split(":")[0]}:${
				s1_end_time.split(":")[1]
			}`;

			let s2_start_time =
				this._state.schedule[this.id][this.selected_date.getDate() * 2 - 1][
					"start"
				].split("T")[1];
			this.s2_start_time = `${s2_start_time.split(":")[0]}:${
				s2_start_time.split(":")[1]
			}`;

			let s2_end_time =
				this._state.schedule[this.id][this.selected_date.getDate() * 2 - 1][
					"end"
				].split("T")[1];
			this.s2_end_time = `${s2_end_time.split(":")[0]}:${
				s2_end_time.split(":")[1]
			}`;

			console.log(
				this.selected_date.getDate() * 2 - 2,
				this._state.schedule[this.id].length,
				s1_start_time,
				s1_end_time,
			);
		} else {
			alert("Fetch old data first!");
		}
	}

	update_day() {
		if (confirm("Are You Sure ?")) {
			this._spinner.show();
			console.log(
				`${this.selected_year}-${
					this.selected_month
				}-${this.selected_date.getDate()}T${this.s1_start_time}:00`,
			);
			let slot1 = {
				title: `${this.s1_end_time}:00`,
				start: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s1_start_time}:00`,
				end: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s1_end_time}:00`,
			};

			let slot2 = {
				title: `${this.s2_end_time}:00`,
				color: "purple",
				start: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s2_start_time}:00`,
				end: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s2_end_time}:00`,
			};

			this._state.schedule[this.id][this.selected_date.getDate() * 2 - 2] =
				slot1;
			this._state.schedule[this.id][this.selected_date.getDate() * 2 - 1] =
				slot2;
			console.log(this._state.schedule[this.id]);
			// this._mqtt.update_schedule(this.id, this.selected_month);
			this.backToDefault();
		}
	}

	update_month() {
		if (confirm("Are You Sure ?")) {
			this._spinner.show();
			let slot1 = {
				title: `${this.s1_end_time}:00`,
				start: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s1_start_time}:00`,
				end: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s1_end_time}:00`,
			};
			let slot2 = {
				title: `${this.s2_end_time}:00`,
				color: "Gray",
				start: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s2_start_time}:00`,
				end: `${this.selected_year}-${this.selected_month}-${("0" + this.selected_date.getDate()).slice(-2)}T${this.s2_end_time}:00`,
			};
			this._state.schedule[this.id] = [];
			for (let index = 0; index < 62; ) {
				this._state.schedule[this.id][index++] = slot1;
				this._state.schedule[this.id][index++] = slot2;
			}
			console.log(this._state.schedule);
			// this._mqtt.update_schedule(this.id, this.selected_month);
			this.backToDefault();
		}
	}

	fetch_config() {
		this._spinner.show();
		setTimeout(() => {
			this._spinner.hide();
		}, 10000);
		// this._mqtt.get_config(this.id);
	}

	// editDevice() {
	// 	this.lat = this._state.devices_list[this.id]["lat"];
	// 	this.lng = this._state.devices_list[this.id]["lng"];
	// 	this.addDevice.patchValue({
	// 		id: this._state.devices_list[this.id]["id"],
	// 		name: this._state.devices_list[this.id]["name"],
	// 		area: this._state.devices_list[this.id]["area"],
	// 		type: this._state.devices_list[this.id]["type"],
	// 		flow_multiplier: this._state.devices_list[this.id]["flow_multiplier"],
	// 	});
	// }

	// changeLocation(location) {
	// 	console.log(location);
	// 	this.lat = location.coords["lat"];
	// 	this.lng = location.coords["lng"];
	// }

	updateDeviceDetails() {
		console.log(this.addDevice);
		if (this.addDevice.valid) {
			let data = this.addDevice.value;
			data.lat = this.lat;
			data.lng = this.lng;
			this._rest.updateDeviceDetails(data).subscribe((result) => {
				console.log(result);
				if (result["success"]) {
					this._tostr.success(result["msg"]);
					this._router.navigate(["/app"]);
					// this._mqtt.start_connction();
				} else {
					this._tostr.error(result["msg"]);
				}
			});
		}
	}

	// edit_config(config) {
	// 	console.log(config);
	// 	if (
	// 		config.key == "prot" ||
	// 		config.key == "timer" ||
	// 		config.key == "amode"
	// 	) {
	// 		this.config_key = config.key;
	// 		this.config_value = config.value === "1" ? true : false;
	// 	} else {
	// 		this.config_key = config.key;
	// 		this.config_value = config.value;
	// 	}
	// }

	// edit_analog(i) {
	// 	console.log(this._state.device_analog_limit[this.id][i]);
	// 	this.analog_key = i;
	// 	this.analog_status =
	// 		this._state.device_analog_limit[this.id][i][0] == 0 ? false : true;
	// 	this.analog_min = this._state.device_analog_limit[this.id][i][1];
	// 	this.analog_max = this._state.device_analog_limit[this.id][i][2];
	// 	this.multiplier = this._state.device_analog_limit[this.id][i][3];
	// 	console.log(
	// 		this.analog_key,
	// 		this.analog_status,
	// 		this.analog_min,
	// 		this.analog_max,
	// 	);
	// }

	// update_config(key) {
	// 	if (
	// 		confirm(
	// 			"Are You Sure, You want to update " + this.config_name[key] + " ?",
	// 		)
	// 	) {
	// 		let value;
	// 		if (key === "prot" || key === "timer" || key === "amode") {
	// 			value = this.config_value ? "1" : "0";
	// 		} else if (key === "time") {
	// 			key = "set_time";
	// 			value = this.config_value;
	// 		} else {
	// 			value = this.config_value;
	// 		}
	// 		// this._mqtt.update_config(this.id, key, value);
	// 		this._spinner.show();
	// 		this.config_key = undefined;
	// 		this._state.device_config[this.id] = [];
	// 	}
	// }

	// update_analog_limit(i) {
	// 	let key = ("0" + this.analog_key).slice(-2);
	// 	let status = this.analog_status ? 1 : 0;
	// 	let min = ("0000" + this.analog_min).slice(-4);
	// 	let max = ("0000" + this.analog_max).slice(-4);
	// 	let value = `${key},${status},${min},${max}`;
	// 	console.log(value);
	// 	if (confirm("Are You Sure ?")) {
	// 		// this._mqtt.update_config(this.id, "analog", value);
	// 		this._spinner.show();
	// 		this.analog_key = undefined;
	// 		this.analog_status = undefined;
	// 		this.analog_min = undefined;
	// 		this.analog_max = undefined;
	// 		this.multiplier = undefined;
	// 		this._state.device_analog_limit[this.id] = [];
	// 	}
	// }

	// update_multiplier(i) {
	// 	let key = ("0" + this.analog_key).slice(-2);
	// 	let status = 0;
	// 	let multiplier = ("0000" + this.multiplier).slice(-4);
	// 	let value = `${key},${status},${multiplier}`;
	// 	console.log(value);
	// 	if (confirm("Are You Sure ?")) {
	// 		// this._mqtt.update_config(this.id, "multpl", value);
	// 		this._spinner.show();
	// 		this.analog_key = undefined;
	// 		this.analog_status = undefined;
	// 		this.analog_min = undefined;
	// 		this.analog_max = undefined;
	// 		this.multiplier = undefined;
	// 		this._state.device_analog_limit[this.id] = [];
	// 	}
	// }

	getRunLogs() {
		this._spinner.show();
		this.report_data = [];
		this._rest
			.getRunLogs(
				this.id,
				this.from_date + " 00:00:00.000000",
				this.to_date + " 23:59:59.999999",
			)
			.subscribe(
				(data) => {
					this._spinner.hide();
					console.log(data);
					this.run_log_data = data["data"];
				},
				(err) => {
					this._spinner.hide();
					console.log(err);
				},
			);
	}

	getdayReportData() {
		this._spinner.show();
		this.report_data = [];
		this._rest
			.getReports(
				this.selectedDevice["id"], // changed from this.id
				this.report_date + " 00:00:00.000000",
				this.report_date + " 23:59:59.999999",
			)
			.subscribe(
				(data) => {
					this._spinner.hide();
					console.log(data);
					// this.report_data = data["data"];
					this.report_data = [];
					for (let i = 0; i < 24; i++) {
						let found = false;
						data["data"].forEach((element) => {
							let time = new Date(element.date)
								.toTimeString()
								.split(" ")[0]
								.split(":")[0];
							if (i === Number(time)) {
								found = true;
								element.to = new Date(element.date).setHours(i + 1, 0, 0, 0);
								this.report_data.push(element);
							}
						});
						if (!found) {
							// if (false) {
							this.report_data.push({
								id: 0,
								uuid: this.uuid,
								date: new Date().setHours(i, 0, 0, 0),
								to: new Date().setHours(i === 23 ? 0 : i + 1, 0, 0, 0),
								run_time: 0,
								flow: undefined,
								rate: 0,
								total_max: 0,
							});
						}
					}
				},
				(err) => {
					this._spinner.hide();
					console.log(err);
				},
			);
	}

	getMonthReportData() {
		let month = this.report_month.toString().split("-");
		let first_day = new Date(Number(month[0]), Number(month[1]), 1);
		let last_day = new Date(Number(month[0]), Number(month[1]), 0);
		let end_date = last_day.getDate();

		console.log(first_day.toDateString());
		this._spinner.show();
		this.month_report_data = [];
		this._rest
			.getMonthReports(
				this.id,
				this.report_month + "-1 00:00:00.000000",
				this.report_month + "-" + end_date + " 23:59:59.999999",
			)
			.subscribe(
				(data) => {
					this._spinner.hide();
					console.log(data);
					this.month_report_data = data["RESULT"];

					// for (let i = 1; i <= end_date; i++) {
					// 	let found = false;
					// 	data["RESULT"].forEach((element) => {
					// 		let day = new Date(element.date).getDate();
					// 		if (i === Number(day)) {
					// 			found = true;
					// 			this.month_report_data.push(element);
					// 		}
					// 	});
					// 	if (!found) {
					// 		this.month_report_data.push({
					// 			id: 0,
					// 			uuid: this.uuid,
					// 			date: new Date(this.report_month + "-" + i),
					// 			run_time: 0,
					// 			flow: undefined,
					// 			rate: 0,
					// 			total_max: 0,
					// 		});
					// 	}
					// }
				},
				(err) => {
					this._spinner.hide();
					console.log(err);
				},
			);
	}

	is_date_recorded(date) {
		return new Date(date) > new Date("2021-05-27T14:49:22.000Z");
	}

	// get_fault_color(switch_id, type) {
	// 	let color = 0;
	// 	// this._state.devices_list[this.id].v1 = 300;
	// 	// this._state.devices_list[this.id].vf1 = 0;
	// 	if (type === "v") {
	// 		switch (switch_id) {
	// 			case 1:
	// 				color = this._state.devices_list[this.id].vf1;
	// 				break;
	// 			case 2:
	// 				color = this._state.devices_list[this.id].vf2;
	// 				break;
	// 			case 3:
	// 				color = this._state.devices_list[this.id].vf3;
	// 				break;
	// 			default:
	// 				color = 0;
	// 				break;
	// 		}
	// 	} else if (type === "c") {
	// 		switch (switch_id) {
	// 			case 1:
	// 				color = this._state.devices_list[this.id].cf1;
	// 				break;
	// 			case 2:
	// 				color = this._state.devices_list[this.id].cf2;
	// 				break;
	// 			case 3:
	// 				color = this._state.devices_list[this.id].cf3;
	// 				break;
	// 			default:
	// 				color = 0;
	// 				break;
	// 		}
	// 	} else {
	// 		color = 0;
	// 	}
	// 	return this.guage_color[color];
	// }

	exportrunexcel() {
		/* table id is passed over here */
		let element = document.getElementById("runreport");
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		/* save to file */
		XLSX.writeFile(
			wb,
			this.uuid + "-" + this.from_date + "-" + this.to_date + ".xlsx",
		);
	}

	exportdayexcel() {
		/* table id is passed over here */
		let element = document.getElementById("dayreport");
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		/* save to file */
		XLSX.writeFile(wb, this.uuid + "-" + this.report_date + ".xlsx");
	}

	exportmonthexcel() {
		/* table id is passed over here */
		let element = document.getElementById("monthreport");
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		/* save to file */
		XLSX.writeFile(wb, this.uuid + "-" + this.report_month + ".xlsx");
	}
}
