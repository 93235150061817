// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: "http://3.6.175.180:20003",
  // mqtt_url: "ws://3.6.175.180:20002",
  // url: "http://localhost:20013",
  // mqtt_url: "ws://localhost:20012",
  url: "https://client.smartiotmeters.in/api/",
  // url: "http://fm.bulfro.com/api",
  mqtt_url: "ws://fm.xyz.com:",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
